<script setup>
import { ref } from 'vue';
import PanelHeader from './PanelHeader.vue';
import PanelSidebar from './PanelSidebar.vue';

import StatsSection from './StatsSection.vue';
import ChatSection from './ChatSection.vue';
import ManageSection from './ManageSection.vue';
import SettingBotsSection from './SettingBotsSection.vue';
import SendingSection from './SendingSection.vue';
import PaymentRequestsSection from './PaymentRequestsSection.vue';

const route = ref('/stats');

function routing(path) {
  route.value = path;
  return route.value;
}
</script>

<template>
  <div class="screen">
    <PanelHeader class="header" />
    <div class="app_content">
      <PanelSidebar
        class="sidebar"
        :routing="routing"
      />

      <div class="content">
        <StatsSection v-if="route === '/stats'" />
        <ChatSection v-if="route === '/chat'" />
        <ManageSection v-if="route === '/manage'" />
        <SettingBotsSection v-if="route === '/setting'" />
        <SendingSection v-if="route === '/sending'" />
        <PaymentRequestsSection v-if="route === '/requests'" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.screen {
  min-height: 100vh;
}

.header {
  height: 10vh;
}

.sidebar {
  min-width: 300px;
}

.app_content {
  height: 90vh;
  display: flex;
}

@media (max-width: 767px) {
  .header {
    height: 5vh;
  }

  .sidebar {
    height: 8vh;
    margin-top: auto;
  }

  .app_content {
    height: 95vh;
    flex-direction: column-reverse;
  }
}

@media (max-width: 1090px) {
  .content {
    display: flex;
    justify-content: center;
  }
}
</style>
