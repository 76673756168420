<script setup>
import { ref } from 'vue';
import SettingBot from './SettingBot.vue';

const bots = ref([
  {
    title: 'TITLE',
    fun_active: 1,
    fun_total: 3,
    isDropdownOpen: false,
    isActive: true,
    functions: [
      {
        title: 'fun',
        isActive: true,
      },
      {
        title: 'fun',
        isActive: false,
      },
      {
        title: 'fun',
        isActive: true,
      },
    ],
  },
  {
    title: 'TITLE',
    fun_active: 1,
    fun_total: 3,
    isDropdownOpen: false,
    isActive: true,
    functions: [
      {
        title: 'fun',
        isActive: true,
      },
      {
        title: 'fun',
        isActive: false,
      },
      {
        title: 'fun',
        isActive: true,
      },
    ],
  },
  {
    title: 'TITLE',
    fun_active: 1,
    fun_total: 3,
    isDropdownOpen: false,
    isActive: true,
    functions: [
      {
        title: 'fun',
        isActive: true,
      },
      {
        title: 'fun',
        isActive: false,
      },
      {
        title: 'fun',
        isActive: true,
      },
    ],
  },
  {
    title: 'TITLE',
    fun_active: 1,
    fun_total: 3,
    isDropdownOpen: false,
    isActive: true,
    functions: [
      {
        title: 'fun',
        isActive: true,
      },
      {
        title: 'fun',
        isActive: false,
      },
      {
        title: 'fun',
        isActive: true,
      },
    ],
  },
  {
    title: 'TITLE',
    fun_active: 1,
    fun_total: 3,
    isDropdownOpen: false,
    isActive: true,
    functions: [
      {
        title: 'fun',
        isActive: true,
      },
      {
        title: 'fun',
        isActive: false,
      },
      {
        title: 'fun',
        isActive: true,
      },
    ],
  },
  {
    title: 'TITLE',
    fun_active: 1,
    fun_total: 3,
    isDropdownOpen: false,
    isActive: true,
    functions: [
      {
        title: 'fun',
        isActive: true,
      },
      {
        title: 'fun',
        isActive: false,
      },
      {
        title: 'fun',
        isActive: true,
      },
    ],
  },
  {
    title: 'TITLE',
    fun_active: 1,
    fun_total: 3,
    isDropdownOpen: false,
    isActive: true,
    functions: [
      {
        title: 'fun',
        isActive: true,
      },
      {
        title: 'fun',
        isActive: false,
      },
      {
        title: 'fun',
        isActive: true,
      },
    ],
  },
]);

function showingBotFunctions(index) {
  bots.value[index].isDropdownOpen = !bots.value[index].isDropdownOpen;
}
</script>

<template>
  <div class="setting-bots-section">
    <p class="setting-bots-section_title">НАСТРОЙКА БОТОВ</p>
    <div class="setting-bots-section_bots">
      <SettingBot
        v-for="(bot, index) in bots"
        :key="index"
        @showingBotFunctions="showingBotFunctions(index)"
        :bot="bot"
      />
    </div>
  </div>
</template>

<style scoped>
.setting-bots-section {
  height: 20%;
  padding: 35px 75px;
}

.setting-bots-section_title {
  font-family: Mont;
  font-size: 48px;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
}

.setting-bots-section_bots {
  height: 60vh;
  min-width: 320px;
  padding-right: 5px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  background: transparent;
  width: 2px;
}
::-webkit-scrollbar-thumb {
  width: 2px;
  height: 30px;
  background-color: rgba(50, 50, 50, 1);
}

@media (max-width: 767px) {
  .setting-bots-section {
    padding: 30px;
  }
  .setting-bots-section_title {
    font-size: 26px;
  }
}
</style>
