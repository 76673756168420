<script>
import { defineComponent } from 'vue';
import Cookies from 'js-cookie';

export default defineComponent({
  data() {
    return {
      login: '',
      password: '',
      adminLogin: 'admin',
      adminPassword: 'admin',
    };
  },
  methods: {
    auth() {
      if (
        this.login === this.adminLogin &&
        this.password === this.adminPassword
      ) {
        Cookies.set('login', this.login, { expires: 7 });
        Cookies.set('password', this.password, { expires: 7 });

        this.$router.push({ name: 'MainLayout' });
      } else {
        alert('Invalid login credentials. Please try again.');
      }
      console.log('Login: ' + this.login);
      console.log('Password: ' + this.password);
    },
  },
});
</script>

<template>
  <div class="screen">
    <div class="login-page">
      <img
        class="login-page_img"
        src="@/assets/penguin.png"
      />
      <p class="login-page_title">АДМИН ПАНЕЛЬ</p>
      <input
        class="login-page_input-login"
        v-model="login"
        placeholder="Логин"
      />
      <input
        class="login-page_input-password"
        type="password"
        v-model="password"
        placeholder="Пароль"
      />
      <button
        class="login-page_btn-auth"
        @click="auth"
      >
        ВОЙТИ
      </button>
      <div class="bloom"></div>
    </div>
  </div>
</template>

<style scoped>
.screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-page_img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.login-page_title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}

input {
  width: 350px;
  height: 50px;
  margin: 10px 0px;
  padding-left: 15px;
  border: 0.5px solid rgba(50, 50, 50, 1);
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  font-family: 'Mont-SemiBold';
  font-weight: 600;
  font-size: 16px;
  color: rgba(246, 246, 246, 1);
  opacity: 70%;
  outline: none;
}

.login-page_btn-auth {
  width: 350px;
  height: 60px;
  margin-top: 20px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 199, 0, 1);
  font-family: 'Mont-SemiBold';
  font-size: 18px;
  font-weight: 800;
  color: rgba(30, 30, 30, 1);
  cursor: pointer;
}

.bloom {
  position: absolute;
  transform: translate(0%, -50%);
  z-index: -1;
  top: 80%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffc700 0%,
    rgba(10, 10, 10, 0) 100%
  );
  width: 100%;
  height: 100%;
  opacity: 20%;
}
</style>
