<script setup>
import Cookies from 'js-cookie';

function logout() {
  Cookies.remove('username');
  Cookies.remove('password');
  this.$router.push({ name: 'LoginPage' });
}
</script>

<template>
  <div class="header">
    <h1 class="header_title">PENGUIN MAINER</h1>
    <a
      class="header_btn-exit"
      href="/"
      @click="logout"
    >
      <img
        class="header_btn-exit_img"
        src="@/assets/exit.svg"
        alt="Exit"
      />
      <span class="header_btn-exit_title">Выйти</span>
    </a>
  </div>
</template>

<style scoped>
.header {
  flex-shrink: 0;
  background: rgba(10, 10, 10, 1);
  display: flex;
  align-items: center;
  border-bottom: rgba(56, 56, 56, 1) 1px solid;
}

.header_title {
  color: rgba(246, 246, 246, 1);
  font-size: 22px;
  font-family: 'Mont';
  margin-left: 30px;
}

.header_btn-exit {
  margin-left: auto;
  text-decoration: none;
  margin-right: 12px;
  display: flex;
  color: rgba(246, 246, 246, 1);
  align-items: center;
}

.header_btn-exit_img {
  display: inline-block;
  width: 20px;
  height: 22px;
}

.header_btn-exit_title {
  margin-left: 5px;
  font-size: 16px;
  font-family: 'Mont-SemiBold';
  font-weight: 500;
}

@media (max-width: 767px) {
  .header_title {
    margin-left: 10px;
    font-size: 17px;
  }
  .header_btn-exit_img {
    width: 14px;
    height: 15px;
  }
  .header_btn-exit_title {
    font-size: 12px;
  }
}
</style>
