<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      activeCategory: 'new',
      activeChat: null,
      newMessage: '',
      chats: [
        {
          id: 1,
          name: 'Роман (123544898798)',
          avatarPath: '@/assets/avatar.png',
          lastMessage: 'Нихуя не работает, разрабы долбоебы...',
          unreadCount: 1,
          status: 'ВОПРОС НЕ РЕШЁН',
          subscriptionEnd: '25.07.2024',
          category: 'new',
          messages: [
            {
              content:
                "Нихуя не работает, разрабы долбоебы. Купил подписку, а меня кинули через хуй. Думал будет кнопка 'бабло', а на деле нихуя не происходит",
              isUser: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Роман (123544898798)',
          avatarPath: '@/assets/avatar.png',
          lastMessage: 'Нихуя не работает, разрабы долбоебы...',
          unreadCount: 1,
          status: 'ВОПРОС НЕ РЕШЁН',
          subscriptionEnd: '25.07.2024',
          category: 'new',
          messages: [
            {
              content:
                "Нихуя не работает, разрабы долбоебы. Купил подписку, а меня кинули через хуй. Думал будет кнопка 'бабло', а на деле нихуя не происходит",
              isUser: false,
            },
          ],
        },
        // Add more chat objects here
      ],
      botResponses: [
        'Спасибо за ваше сообщение. Чем я могу помочь?',
        'Понимаю вашу проблему. Давайте попробуем следующее...',
        'Извините за неудобства. Мы работаем над улучшением нашего сервиса.',
        'Можете уточнить, что именно не работает?',
        'Спасибо за обратную связь. Мы передадим эту информацию разработчикам.',
      ],
    };
  },
  computed: {
    filteredChats() {
      return this.chats.filter((chat) => chat.category === this.activeCategory);
    },
  },
  methods: {
    setActiveCategory(category) {
      this.activeCategory = category;
    },
    setActiveChat(chat) {
      this.activeChat = chat;
      this.activeChat.unreadCount = 0;
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    sendMessage() {
      if (this.newMessage.trim() && this.activeChat) {
        this.activeChat.messages.push({
          content: this.newMessage,
          isUser: true,
        });
        this.activeChat.lastMessage = this.newMessage;
        this.newMessage = '';
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        this.simulateBotResponse();
        this.saveChatsToLocalStorage();
      }
    },
    simulateBotResponse() {
      if (this.activeChat) {
        setTimeout(() => {
          const randomResponse =
            this.botResponses[
              Math.floor(Math.random() * this.botResponses.length)
            ];
          this.activeChat.messages.push({
            content: randomResponse,
            isUser: false,
          });
          this.activeChat.lastMessage = randomResponse;
          this.activeChat.unreadCount += 1;
          this.$nextTick(() => {
            this.scrollToBottom();
          });
          this.saveChatsToLocalStorage();
        }, 1000);
      }
    },
    scrollToBottom() {
      if (this.$refs.chatContainer) {
        this.$refs.chatContainer.scrollTop =
          this.$refs.chatContainer.scrollHeight;
      }
    },
    markAsDone() {
      if (this.activeChat) {
        this.activeChat.status = 'ВОПРОС РЕШЁН';
        this.activeChat.category = 'done';
        this.saveChatsToLocalStorage();
      }
    },
    saveChatsToLocalStorage() {
      localStorage.setItem('chats', JSON.stringify(this.chats));
    },
    loadChatsFromLocalStorage() {
      const savedChats = localStorage.getItem('chats');
      if (savedChats) {
        this.chats = JSON.parse(savedChats);
      }
    },
    logout() {
      Cookies.remove('username');
      Cookies.remove('password');
      this.$router.push({ name: 'LoginPage' });
    },
  },
  created() {
    this.loadChatsFromLocalStorage();
  },
  updated() {
    this.scrollToBottom();
  },
};
</script>

<template>
  <div class="container">
    <div class="content">
      <div class="mainpart">
        <div class="contacts">
          <div class="categories">
            <p
              :class="{ 'active-categorie': activeCategory === 'new' }"
              @click="setActiveCategory('new')"
            >
              НОВЫЕ
            </p>
            <p
              :class="{ 'active-categorie': activeCategory === 'in-progress' }"
              @click="setActiveCategory('in-progress')"
            >
              В ПРОЦЕССЕ
            </p>
            <p
              :class="{ 'active-categorie': activeCategory === 'done' }"
              @click="setActiveCategory('done')"
            >
              ЗАКРЫТЫЕ
            </p>
          </div>
          <div class="list-contacts">
            <div
              v-for="(chat, index) in filteredChats"
              :key="index"
              class="contact"
              @click="setActiveChat(chat)"
            >
              <img
                :src="chat.avatar"
                class="avatar-contact"
              />
              <div class="contact-info">
                <p class="name-contact">{{ chat.name }}</p>
                <p class="last-message">{{ chat.lastMessage }}</p>
              </div>
              <p
                v-if="chat.unreadCount > 0"
                class="amount-unread"
              >
                {{ chat.unreadCount }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="chat"
          v-if="activeChat"
        >
          <div class="chat-header">
            <img
              :src="activeChat.avatar"
              class="avatar-chat"
            />
            <div class="info-contact">
              <p class="name-chat">{{ activeChat.name }}</p>
              <p class="subscription">
                Подписка активна до: {{ activeChat.subscriptionEnd }}
              </p>
            </div>
            <p
              class="status"
              :class="{ 'status-solved': activeChat.status === 'ВОПРОС РЕШЁН' }"
            >
              {{ activeChat.status }}
            </p>
            <button
              v-if="activeChat.status !== 'ВОПРОС РЕШЁН'"
              class="ready"
              @click="markAsDone"
            >
              ГОТОВО
            </button>
          </div>
          <div
            class="main-chat"
            ref="chatContainer"
          >
            <div
              v-for="(message, index) in activeChat.messages"
              :key="index"
              :class="[
                'message',
                message.isUser ? 'your-message' : 'contact-message',
              ]"
            >
              <p
                :class="
                  message.isUser
                    ? 'content-your-message'
                    : 'content-contact-message'
                "
              >
                {{ message.content }}
              </p>
            </div>
          </div>
          <div
            class="input-your-message"
            v-if="activeChat.status !== 'ВОПРОС РЕШЁН'"
          >
            <textarea
              rows="4"
              placeholder="Введите ваше сообщение"
              v-model="newMessage"
              @keyup.enter="sendMessage"
            ></textarea>
            <button
              class="submit"
              @click="sendMessage"
            >
              <img
                class="submit-icon"
                src="@/assets/submit.svg"
                alt="Submit"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
::-webkit-scrollbar {
  background: transparent;
  width: 2px;
}
::-webkit-scrollbar-thumb {
  width: 2px;
  height: 30px;
  background-color: rgba(50, 50, 50, 1);
}

.submit-icon {
  position: relative;
  margin-right: 50px;
  margin-bottom: 60px;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.status-solved {
  background-color: #4caf50;
  color: black !important;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.header {
  flex-shrink: 0;
  background: rgba(10, 10, 10, 1);
  height: 75px;
  display: flex;
  align-items: center;
  border-bottom: rgba(56, 56, 56, 1) 1px solid;
}

.label {
  color: rgba(246, 246, 246, 1);
  font-size: 22px;
  font-family: 'Mont';
  margin-left: 30px;
}

.exit-link {
  margin-left: auto;
  text-decoration: none;
  margin-right: 12px;
  display: flex;
  color: rgba(246, 246, 246, 1);
  align-items: center;
}

.active-categorie {
  opacity: 100% !important;
}

.exit-icon {
  display: inline-block;
  width: 20.6px;
  height: 22.89px;
}

.exit {
  margin-left: 5px;
  font-size: 16px;
  font-family: 'Mont-SemiBold';
  font-weight: 500;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  flex-shrink: 0;
  width: 320px;
  background-color: rgba(19, 19, 19, 1);
  overflow-y: auto;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option {
  padding: 15px 10%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-option {
  margin-right: 10px;
  display: inline-block;
  opacity: 70%;
}

.name-option {
  color: #f9f9f9;
  font-family: 'Mont-SemiBold';
  font-weight: 550;
  font-size: 13px;
  opacity: 70%;
}

#current-option {
  color: rgb(255, 255, 255);
  opacity: 100%;
}

.mainpart {
  display: flex;
  flex: 1;
  overflow: hidden;
  font-family: Mont-light;
}

.contacts {
  flex-shrink: 0;
  width: 45vh;
  border-right: 1px solid rgba(39, 39, 39, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.categories {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(39, 39, 39, 1);
}

.categories p {
  opacity: 60%;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont';
  margin: 0 10px;
  cursor: pointer;
}

#active-categorie {
  opacity: 100%;
}

.list-contacts {
  flex: 1;
  overflow-y: auto;
}

.contact {
  display: flex;
  height: 80px;
  border-bottom: 1px solid rgba(39, 39, 39, 1);
  align-items: center;
  padding: 0 10px;
}

.avatar-contact {
  border-radius: 50%;
  height: 50%;
  margin-right: 10px;
}

.contact-info {
  flex: 1;
}

.name-contact {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-light';
  font-size: 1.6vh;
  margin: 0;
}

.last-message {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-light';
  opacity: 70%;
  font-size: 1.3vh;
  margin: 5px 0 0;
}

.amount-unread {
  height: 3vh;
  width: 3vh;
  font-family: 'Mont';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7vh;
  border-radius: 50%;
  background: rgba(79, 208, 0, 1);
  margin: 0;
}

.chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid rgba(39, 39, 39, 1);
  padding: 0 10px;
}

.avatar-chat {
  border-radius: 50%;
  height: 80%;
  margin-right: 10px;
}

.info-contact {
  flex: 1;
}

.name-chat {
  font-family: 'Mont';
  font-size: 1.6vh;
  color: rgba(249, 249, 249, 1);
  margin: 0;
}

.subscription {
  font-family: 'Mont-light';
  font-size: 1.4vh;
  opacity: 70%;
  color: rgba(249, 249, 249, 1);
  margin: 5px 0 0;
}

.status {
  color: rgba(249, 249, 249, 1);
  font-family: 'Mont';
  font-size: 13px;
  margin: 0 15px;
}

.ready {
  background: rgba(255, 199, 0, 1);
  border: 0;
  height: 70%;
  font-size: 13px;
  font-family: 'Mont';
  padding: 0 20px;
  border-radius: 10px;
}

.main-chat {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.message {
  margin-bottom: 10px;
}

.your-message {
  text-align: right;
}

.contact-message {
  text-align: left;
}

.content-your-message,
.content-contact-message {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.content-your-message {
  background-color: #0a0a0a;
  color: #f9f9f9;
}

.content-contact-message {
  background-color: #0a0a0a;
  color: #f9f9f9;
}

.input-your-message {
  display: flex;
  margin: 40px;
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 1px solid rgba(50, 50, 50, 1);
}

textarea {
  resize: none;
  width: 93%;
  padding: 10px;
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  background-color: transparent;
  border: none;
  outline: none;
}

.submit {
  width: 7%;
  background: rgba(0, 0, 0, 0);
  border: 0;
}
</style>
