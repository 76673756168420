<script setup>
import { ref, onMounted } from 'vue';

const message = ref('');
const selectedLanguage = ref('Русский');
const selectedFilter = ref('Разослать всем пользователям');
const messageHistory = ref([]);

const sendMessage = () => {
  if (message.value.trim() === '') return;

  const newMessage = {
    content: message.value,
    to: selectedFilter.value,
    lang: selectedLanguage.value === 'Русский' ? 'RU' : 'EN',
    time: new Date().toLocaleString('ru-RU'),
  };

  messageHistory.value.unshift(newMessage);
  saveMessageHistory();
  message.value = '';
};

const saveMessageHistory = () => {
  localStorage.setItem('messageHistory', JSON.stringify(messageHistory.value));
};

onMounted(() => {
  const savedHistory = localStorage.getItem('messageHistory');
  if (savedHistory) {
    messageHistory.value = JSON.parse(savedHistory);
  }
});
</script>

<template>
  <div class="sending-section">
    <p class="sending-section_title">РАССЫЛКА</p>
    <div class="sending-section_action">
      <div class="sending-section_action_input_message">
        <textarea
          v-model="message"
          placeholder="Введите ваше сообщение"
          rows="4"
        ></textarea>

        <img
          class="sending-section_action_input_message_submit"
          src="@/assets/submit.svg"
          @click="sendMessage"
        />
      </div>
      <div class="sending-section_action_radio">
        <div class="sending-section_action_radio-language">
          <p class="sending-section_action_radio_title">Язык бота</p>
          <label
            class="sending-section_action_radio_input"
            v-for="lang in ['Русский', 'English']"
            :key="lang"
          >
            <input
              class="real-radio"
              type="radio"
              name="p"
              :value="lang"
              v-model="selectedLanguage"
            />
            <span class="custom-radio"></span>
            <p class="language">{{ lang }}</p>
          </label>
        </div>
        <div class="sending-section_action_radio-filter">
          <p class="sending-section_action_radio_title">Фильтрация</p>
          <label
            class="sending-section_action_radio_input"
            v-for="filter in [
              'Разослать всем пользователям',
              'Разослать пользователям без подписки',
              'Разослать пользователям с активной подпиской',
            ]"
            :key="filter"
          >
            <input
              class="real-radio"
              type="radio"
              name="l"
              :value="filter"
              v-model="selectedFilter"
            />
            <span class="custom-radio"></span>
            <p class="language">{{ filter }}</p>
          </label>
        </div>
      </div>
    </div>
    <h2 class="mini-label">ИСТОРИЯ СООБЩЕНИЙ</h2>
    <div class="story-messages">
      <div
        class="message"
        v-for="(msg, index) in messageHistory"
        :key="index"
      >
        <p class="content-message">{{ msg.content }}</p>
        <div class="info">
          <p class="to">
            {{ msg.to }} <span class="lang">{{ msg.lang }}</span>
          </p>
          <p class="time">{{ msg.time }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
::-webkit-scrollbar {
  background: transparent;
  width: 2px;
}
::-webkit-scrollbar-thumb {
  width: 2px;
  height: 30px;
  background-color: rgba(50, 50, 50, 1);
}

textarea {
  width: 90%;
  height: 120px;
  padding: 20px 0px;
  font-family: Mont-SemiBold;
  color: rgba(255, 255, 255, 1);
  resize: none;
  border: none;
  outline: none;
  background-color: transparent;
}

.sending-section {
  padding: 35px 75px;
}

.sending-section_title {
  font-size: 48px;
  position: relative;
  color: rgb(255, 255, 255);
  font-family: Mont;
}

.sending-section_action_input_message {
  width: 60vw;
  min-width: 320px;
  height: 125px;
  margin-top: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 1px solid rgba(50, 50, 50, 1);
}

.sending-section_action_input_message_submit {
  width: 24px;
  height: 24px;
  margin-bottom: auto;
}

.sending-section_action_radio {
  display: flex;
  justify-content: space-between;
}

.sending-section_action_radio-language {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont';
}

.sending-section_action_radio_title {
  margin-top: 10px;
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
}

.sending-section_action_radio-filter {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont';
}

.custom-radio {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 1px solid rgba(50, 50, 50, 1);
}
.custom-radio::before {
  content: '';
  position: absolute;
  background-color: yellow;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.2s ease-in;
}
.real-radio:checked + .custom-radio::before {
  transform: translate(-50%, -50%) scale(1);
}
.real-radio {
  display: none;
}
.sending-section_action_radio_input {
  margin-top: 10px;
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
}
.language {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-light';
  opacity: 70%;
  font-size: 14px;
  margin-left: 20px;
}
.filter {
  margin-left: 20%;
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont';
}
.mini-label {
  color: rgba(246, 246, 246, 1);
  font-size: 30px;
  font-family: 'Mont';
  margin-top: 20px;
}
.content-message {
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-light';
  opacity: 70%;
  font-size: 14px;
}
.message {
  margin-top: 15px;
}
.info {
  display: flex;
  margin-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  border-bottom: 1px solid rgba(56, 56, 56, 1);
}
.to {
  color: #ffffff;
  font-family: 'Mont-SemiBold';
  margin-right: 10px;
}
.lang {
  color: #ffffff;
  font-family: 'Mont-SemiBold';
}
.time {
  margin-right: 10px;
  margin-left: auto;
  text-align: right;
  color: #ffffff;
  font-family: 'Mont-SemiBold';
}
.story-messages {
  width: 60vw;
  min-width: 320px;
  height: 25vh;
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .sending-section {
    padding: 30px 30px 0px 30px;
  }

  .sending-section_title {
    font-size: 26px;
  }

  .sending-section_action_input_message {
    height: 110px;
  }

  .sending-section_action {
    display: flex;
    flex-direction: column-reverse;
  }

  .sending-section_action_radio {
    flex-direction: column;
  }

  .mini-label {
    font-size: 18px;
  }

  .content-message {
    font-size: 10px;
  }

  .info {
    font-size: 12px;
  }
}
</style>
