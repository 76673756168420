<script setup>
import { ref, onMounted } from 'vue';

const profileStorage = {
  123544898798: {
    name: 'Роман',
    id: '123544898798',
    subscriptionEnd: '25.07.2024',
    slots: '4/5',
    balance: '30',
    invited: '3',
    availableForWithdrawal: '5.54',
    totalEarned: '155.54',
    pfp: require('@/assets/avatar.png'),
  },
};

const currentProfile = ref(null);
const userId = ref('');
// const profile = profileStorage[userId.value];
// currentProfile.value = profile;

const fetchProfile = () => {
  const profile = profileStorage[userId.value];
  if (profile) {
    currentProfile.value = profile;
  } else {
    alert('Профиль не найден');
    currentProfile.value = null;
  }
};

const updateBalance = (amount) => {
  if (currentProfile.value) {
    const newBalance =
      parseFloat(currentProfile.value.balance) + parseFloat(amount);
    currentProfile.value.balance = newBalance.toFixed(2);
  }
};

const updateSlots = (change) => {
  if (currentProfile.value) {
    const [current, max] = currentProfile.value.slots.split('/');
    const newCurrent = Math.max(
      0,
      Math.min(parseInt(current) + parseInt(change), parseInt(max))
    );
    currentProfile.value.slots = `${newCurrent}/${max}`;
  }
};

const transferAccount = (newId) => {
  if (currentProfile.value && newId) {
    profileStorage[newId] = { ...currentProfile.value, id: newId };
    delete profileStorage[currentProfile.value.id];
    currentProfile.value = profileStorage[newId];
  }
};

onMounted(() => {
  currentProfile.value = null;
});
</script>

<template>
  <div class="manage-section">
    <div class="manage-section_title">УПРАВЛЕНИЕ</div>
    <div class="manage-section_input-search">
      <input
        class="manage-section_input-search_input"
        v-model="userId"
        @keyup.enter="fetchProfile"
        placeholder="Введите ID пользователя"
      />
      <img
        @click="fetchProfile"
        class="manage-section_input-search_btn_img"
        src="@/assets/submit.svg"
      />
    </div>

    <div
      class="manage-section_content"
      v-if="currentProfile"
    >
      <div class="manage-section_profile">
        <div class="manage-section_profile_user">
          <img
            class="manage-section_profile_img"
            :src="currentProfile.pfp"
          />
          <div class="manage-section_profile_info">
            <div class="manage-section_profile_info_username">
              {{ currentProfile.name }} ({{ currentProfile.id }})
            </div>
            <div class="manage-section_profile_info_sub-until">
              Подписка активна до:
              <span class="manage-section_profile_info_sub-until_date">{{
                currentProfile.subscriptionEnd
              }}</span>
            </div>
          </div>
        </div>

        <button class="manage-section_profile_btn-renew-sub">
          ПРОДЛИТЬ ПОДПИСКУ
        </button>
      </div>

      <div class="manage-section_profile_statistics">
        <div class="manage-section_profile_statistics-block">
          <p class="manage-section_profile_statistics-block_slots">
            Слоты: <span> {{ currentProfile.slots }}</span>
          </p>
          <p class="manage-section_profile_statistics-block_balance">
            Баланс: <span>{{ currentProfile.balance }}$</span>
          </p>
        </div>
        <div class="manage-section_profile_statistics-block">
          <p class="manage-section_profile_statistics-block_invite">
            Приглашено: <span> {{ currentProfile.invited }}</span>
          </p>
          <p class="manage-section_profile_statistics-block_available">
            Доступно для вывода:
            <span> {{ currentProfile.availableForWithdrawal }}$</span>
          </p>
          <p class="manage-section_profile_statistics-block_total">
            Заработано всего: <span>{{ currentProfile.totalEarned }}$</span>
          </p>
        </div>
      </div>

      <div class="manage-section_profile_action">
        <p>Введите сумму на которую нужно изменить баланс, $</p>
        <div class="manage-section_profile_action_balance">
          <input
            v-model="balanceChange"
            placeholder="-20"
          />
          <button @click="updateBalance(balanceChange)">ИЗМЕНИТЬ БАЛАНС</button>
        </div>
        <p>Введите количество слотов для изменения</p>
        <div class="manage-section_profile_action_slots">
          <input
            v-model="slotsChange"
            placeholder="-5"
          />
          <button @click="updateSlots(slotsChange)">ИЗМЕНИТЬ СЛОТЫ</button>
        </div>
        <p>Введите ID на который нужно перенести действующий аккаунт</p>
        <div class="manage-section_profile_action_balance_account">
          <input
            v-model="newAccountId"
            placeholder="123544898798"
          />
          <button @click="transferAccount(newAccountId)">
            ПЕРЕНЕСТИ АККАУНТ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input {
  font-family: Mont-SemiBold;
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
  border: none;
  outline: none;
}

button {
  height: 40px;
  width: 200px;
  border: none;
  border-radius: 10px;
  font-family: Mont;
  font-size: 13px;
  font-weight: 800;
  color: rgba(30, 30, 30, 1);
  background: rgba(255, 199, 0, 1);
  cursor: pointer;
}

.manage-section {
  padding-top: 35px;
  padding-left: 75px;
  font-family: Mont-SemiBold;
}

.manage-section_title {
  font-family: Mont;
  font-size: 48px;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
}

.manage-section_input-search {
  display: flex;
  width: 45vw;
  min-width: 320px;
  padding: 10px;
  align-items: center;
  border: 1px solid rgba(50, 50, 50, 1);
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
}

.manage-section_input-search_input {
  width: 95%;
  opacity: 70%;
}

.manage-section_input-search_btn_img {
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
  opacity: 0.7px;
  cursor: pointer;
}

.manage-section_content {
  min-width: 320px;
}

.manage-section_profile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.manage-section_profile_user {
  display: flex;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
}

.manage-section_profile_img {
  width: 64px;
  height: 64px;
  border: 1px solid rgba(37, 37, 37, 1);
  border-radius: 50%;
}

.manage-section_profile_info {
  margin: 0px 10px;
  color: rgba(249, 249, 249, 1);
}

.manage-section_profile_info_username {
  font-size: 20px;
  font-weight: 700;
}

.manage-section_profile_info_sub-until {
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 600;
  opacity: 70%;
}

.manage-section_profile_info_sub-until_date {
  font-size: 14px;
  font-weight: 800;
}

.manage-section_profile_statistics {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  margin-top: 10px;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  color: rgba(135, 135, 135, 1);
}

.manage-section_profile_statistics span {
  font-size: 16px;
  font-weight: 700;
  color: rgba(246, 246, 246, 1);
}

.manage-section_profile_action {
  display: flex;
  flex-direction: column;
}

.manage-section_profile_action div {
  display: flex;
  flex-wrap: nowrap;
}

.manage-section_profile_action p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: rgba(135, 135, 135, 1);
}

.manage-section_profile_action input {
  height: 40px;
  width: 250px;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid rgba(50, 50, 50, 1);
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
}

@media (max-width: 915px) {
  input {
    font-size: 12px;
  }

  button {
    width: 150px;
    font-size: 10px;
  }

  .manage-section_profile_action input {
    width: 170px;
  }

  .manage-section_title {
    font-size: 26px;
  }

  .manage-section_profile_img {
    height: 36px;
    width: 36px;
  }

  .manage-section_profile_info_username {
    font-size: 14px;
  }

  .manage-section_profile_info_sub-until {
    font-size: 12px;
  }

  .manage-section_profile_info_sub-until_date {
    font-size: 12px;
  }

  .manage-section_profile_statistics {
    max-width: 250px;
    flex-direction: column;
    font-size: 14px;
  }

  .manage-section_profile_statistics span {
    font-size: 14px;
  }

  .manage-section_profile_action p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  button {
    height: 35px;
  }

  .manage-section {
    padding: 30px 30px 15px 30px;
  }

  .manage-section_profile_statistics {
    max-width: 250px;
    flex-direction: column;
    font-size: 13px;
  }

  .manage-section_profile_statistics span {
    font-size: 13px;
  }

  .manage-section_profile_action p {
    font-size: 13px;
  }

  .manage-section_profile_action input {
    height: 35px;
  }
}
</style>
