<script></script>

<template>
  <div class="stats-section">
    <p class="stats-section_title">СТАТИСТИКА</p>

    <table class="stats-section_table_dekstop">
      <thead class="stats-section_table_header">
        <th></th>
        <th class="stats-section_table_header_title">ПРИБЫЛЬ</th>
        <th class="stats-section_table_header_title">КОЛИЧЕСТВО ПОДПИСОК</th>
        <th class="stats-section_table_header_title">КОЛИЧЕСТВО УЧАСТНИКОВ</th>
      </thead>
      <tbody class="stats-section_table_body">
        <tr class="stats-section_table_row">
          <td class="stats-section_table-time">ВСЕГО</td>
          <td class="stats-section_table-cash">9800000$</td>
          <td class="stats-section_table-amount">1560804</td>
          <td class="stats-section_table-amount">16585064</td>
        </tr>
        <tr class="stats-section_table_row">
          <td class="stats-section_table-time">1 ДЕНЬ</td>
          <td class="stats-section_table-cash">+156$</td>
          <td class="stats-section_table-amount">+30</td>
          <td class="stats-section_table-amount">+56</td>
        </tr>
        <tr class="stats-section_table_row">
          <td class="stats-section_table-time">7 ДНЕЙ</td>
          <td class="stats-section_table-cash">+24000$</td>
          <td class="stats-section_table-amount">+6500</td>
          <td class="stats-section_table-amount">+12360</td>
        </tr>
        <tr>
          <td class="stats-section_table-time">30 ДНЕЙ</td>
          <td class="stats-section_table-cash">+1200546$</td>
          <td class="stats-section_table-amount">+150486</td>
          <td class="stats-section_table-amount">+350466</td>
        </tr>
      </tbody>
    </table>

    <table class="stats-section_table_mobile">
      <tbody class="stats-section_table_body">
        <tr>
          <td>
            <tr>
              <td class="stats-section_table-time">ВСЕГО</td>
            </tr>
          </td>
          <td>
            <tr>
              <td class="stats-section_table_header_title">ПРИБЫЛЬ</td>
              <td class="stats-section_table_header_title">ПОДПИСКИ</td>
              <td class="stats-section_table_header_title">УЧАСТНИКИ</td>
            </tr>
            <tr>
              <td class="stats-section_table-cash">9800000$</td>
              <td class="stats-section_table-amount">1560804</td>
              <td class="stats-section_table-amount">16585064</td>
            </tr>
          </td>
        </tr>
        <tr class="stats-section_table_row">
          <td>
            <tr>
              <td class="stats-section_table-time">1 ДЕНЬ</td>
            </tr>
          </td>
          <td>
            <tr>
              <td class="stats-section_table_header_title">ПРИБЫЛЬ</td>
              <td class="stats-section_table_header_title">ПОДПИСКИ</td>
              <td class="stats-section_table_header_title">УЧАСТНИКИ</td>
            </tr>
            <tr>
              <td class="stats-section_table-cash">9800000$</td>
              <td class="stats-section_table-amount">1560804</td>
              <td class="stats-section_table-amount">16585064</td>
            </tr>
          </td>
        </tr>
        <tr class="stats-section_table_row">
          <td>
            <tr>
              <td class="stats-section_table-time">7 ДНЕЙ</td>
            </tr>
          </td>
          <td>
            <tr>
              <td class="stats-section_table_header_title">ПРИБЫЛЬ</td>
              <td class="stats-section_table_header_title">ПОДПИСКИ</td>
              <td class="stats-section_table_header_title">УЧАСТНИКИ</td>
            </tr>
            <tr>
              <td class="stats-section_table-cash">9800000$</td>
              <td class="stats-section_table-amount">1560804</td>
              <td class="stats-section_table-amount">16585064</td>
            </tr>
          </td>
        </tr>
        <tr class="stats-section_table_row">
          <td>
            <tr>
              <td class="stats-section_table-time">30 ДНЕЙ</td>
            </tr>
          </td>
          <td>
            <tr>
              <td class="stats-section_table_header_title">ПРИБЫЛЬ</td>
              <td class="stats-section_table_header_title">ПОДПИСКИ</td>
              <td class="stats-section_table_header_title">УЧАСТНИКИ</td>
            </tr>
            <tr>
              <td class="stats-section_table-cash">9800000$</td>
              <td class="stats-section_table-amount">1560804</td>
              <td class="stats-section_table-amount">16585064</td>
            </tr>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.stats-section {
  padding: 35px 75px;
  display: flex;
  flex-direction: column;
}

.stats-section_title {
  font-family: 'Mont';
  font-size: 48px;
  color: rgba(255, 255, 255, 1);
}

.stats-section_table_dekstop {
  margin-top: 20px;
  border: 1px solid rgba(50, 50, 50, 1);
  border-radius: 10px;
  border-spacing: 40px 10px;
  background: linear-gradient(
    18deg,
    #1b2e10 10%,
    rgba(30, 30, 30, 1) 30%,
    rgba(17, 17, 17, 1) 100%
  );
}

.stats-section_table_mobile {
  margin-top: 20px;
  display: none;
}

.stats-section_table_header_title {
  color: rgba(246, 246, 246, 1);
  font-size: 14px;
  text-align: left;
  font-family: 'Mont-SemiBold';
  font-weight: 600;
}

.stats-section_table-time {
  padding-top: 10px;
  text-wrap: nowrap;
  color: rgba(246, 246, 246, 1);
  font-size: 22px;
  text-align: left;
  font-family: 'Mont-SemiBold';
  font-weight: 600;
}

.stats-section_table-cash {
  color: rgba(79, 208, 0, 1);
  text-align: left;
  font-size: 40px;
  font-family: 'Mont-SemiBold';
  font-weight: 600;
}

.stats-section_table-amount {
  color: rgba(255, 199, 0, 1);
  text-align: left;
  font-size: 42px;
  font-family: 'Mont-SemiBold';
  font-weight: 600;
}

.stats-section_table_row {
  box-shadow: 0px 1px 0px 0px rgba(39, 39, 39, 1);
}

@media (max-width: 1290px) {
  .stats-section_table_header_title {
    font-size: 12px;
  }

  .stats-section_table-time {
    font-size: 16px;
  }

  .stats-section_table-cash {
    font-size: 26px;
  }

  .stats-section_table-amount {
    font-size: 28px;
  }
}

@media (max-width: 1090px) {
  td {
    padding-right: 15px;
  }

  .stats-section_table_dekstop {
    display: none;
  }

  .stats-section_table_mobile {
    display: block;
  }
}

@media (max-width: 820px) {
  .stats-section {
    padding: 30px 30px;
  }

  .stats-section_title {
    font-size: 26px;
  }

  .stats-section_table_header_title {
    font-size: 10px;
  }

  .stats-section_table-time {
    font-size: 14px;
  }

  .stats-section_table-cash {
    font-size: 18px;
  }

  .stats-section_table-amount {
    font-size: 18px;
  }
}
</style>
