import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/LoginPage.vue';
import MainLayout from './components/MainLayout.vue';

import Cookies from 'js-cookie';

const adminLogin = 'admin';
const adminPassword = 'admin';

const routes = [
  { path: '/', name: 'LoginPage', component: LoginPage },
  {
    path: '/main',
    name: 'MainLayout',
    component: MainLayout,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const login = Cookies.get('login');
    const password = Cookies.get('password');
    if (login === adminLogin && password === adminPassword) {
      next();
    } else {
      next({ name: 'LoginPage' });
    }
  } else {
    next();
  }
});

export default router;
