<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import PaymentRequest from './PaymentRequest.vue';

const requests = ref([
  {
    id: 123123,
    name: 'Username',
    totalEarned: 156,
    adressWallet: '0x36696169c63e42cd08ce11f5deebbcebae652050',
    amount: 152.51,
    date: '15.08.2024, 15:36',
    state: true,
    avatar: 'avatar.png',
  },
  {
    id: 123123,
    name: 'Username',
    totalEarned: 156,
    adressWallet: '0x36696169c63e42cd08ce11f5deebbcebae652050',
    amount: 152.51,
    date: '15.08.2024, 15:36',
    state: false,
    avatar: 'avatar.png',
  },
  {
    id: 123123,
    name: 'Username',
    totalEarned: 156,
    adressWallet: '0x36696169c63e42cd08ce11f5deebbcebae652050',
    amount: 152.51,
    date: '15.08.2024, 15:36',
    state: false,
    avatar: 'avatar.png',
  },
]);

const statistics = {
  moneyEverything: 156644.21,
  balanceNow: 16234.43,
  paidOut: 98555.66,
  paymentsPending: 6123.21,
};

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
});

const route = ref('/requests');

const windowWidth = ref(window.innerWidth);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <div class="section">
    <div class="section_mobile">
      <p class="section_routes_title">ЗАЯВКИ НА ВЫПЛАТУ</p>
      <div class="section_routes">
        <p
          class="section_option"
          :class="route === '/requests' ? 'section_current_option' : ''"
          @click="route = '/requests'"
        >
          ЗАЯВКИ
        </p>
        <p
          class="section_option"
          :class="route === '/statistics' ? 'section_current_option' : ''"
          @click="route = '/statistics'"
        >
          СТАТИСТИКА
        </p>
      </div>
    </div>
    <div
      class="section_requests"
      v-if="windowWidth > 1200 || route === '/requests'"
    >
      <p class="section_requests_title">ЗАЯВКИ НА ВЫПЛАТУ</p>
      <div class="section_requests_content">
        <PaymentRequest
          v-for="(request, index) in requests"
          :key="index"
          :request="request"
        />
      </div>
    </div>
    <div
      class="section_statistics"
      v-if="windowWidth > 1200 || route === '/statistics'"
    >
      <p class="section_statistics_title">СТАТИСТИКА</p>
      <p class="section_statistics_desc">
        Реф.деньги за все время:
        <span class="section_statistics_desc_value"
          >{{ formatter.format(statistics.moneyEverything) }}$</span
        >
      </p>
      <p class="section_statistics_desc">
        На балансах сейчас:
        <span class="section_statistics_desc_value"
          >{{ formatter.format(statistics.balanceNow) }}$</span
        >
      </p>
      <p class="section_statistics_desc">
        Выплачено:
        <span class="section_statistics_desc_value"
          >{{ formatter.format(statistics.paidOut) }}$</span
        >
      </p>
      <p class="section_statistics_desc">
        Ожидают выплаты:
        <span class="section_statistics_desc_value"
          >{{ formatter.format(statistics.paymentsPending) }}$</span
        >
      </p>
    </div>
  </div>
</template>

<style scoped>
.section {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  font-family: Mont-SemiBold;
}

.section_mobile {
  display: none;
}

.section_routes {
  width: 320px;
  padding: 0px 30px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section_routes p {
  font-size: 16px;
  font-weight: 800;
}

.section_option {
  color: rgb(255, 255, 255);
  opacity: 60%;
}

.section_current_option {
  opacity: 100%;
}

.section_requests {
  padding-top: 35px;
}

.section_requests_title {
  padding: 0px 75px;
  font-family: Mont;
  font-size: 48px;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
}

.section_requests_content {
  height: 73vh;
  /* padding-right: 5px; */
  padding: 0px 30px;

  overflow-y: scroll;
  overflow-x: hidden;
}

.section_statistics {
  width: 400px;
  padding: 50px 30px;
  border-left: 1px solid rgba(39, 39, 39, 1);
}

.section_statistics_title {
  font-family: Mont;
  font-size: 32px;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
}

.section_statistics_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: rgba(246, 246, 246, 0.8);
}

.section_statistics_desc_value {
  font-size: 16px;
  font-weight: 800;
  color: rgba(246, 246, 246, 1);
}

::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  width: 5px;
  height: 55px;
  background-color: rgba(50, 50, 50, 1);
}

@media (max-width: 767px) {
  .section_mobile {
    border-bottom: 1px solid rgba(56, 56, 56, 1);
  }
}

@media (max-width: 1200px) {
  .section {
    flex-direction: column;
    justify-content: flex-start;
  }

  .section_mobile {
    display: block;
  }

  .section_routes_title {
    text-wrap: nowrap;
    padding: 20px 30px;
    font-size: 26px;
    font-family: Mont;
    font-weight: 800;
    color: rgba(255, 255, 255);
  }

  .section_requests_title {
    display: none;
  }

  .section_requests {
    padding-top: 0px;
  }

  .section_statistics {
    padding: 20px 30px;
    width: 350px;
  }

  .section_statistics_title {
    display: none;
  }

  .section_statistics_desc {
    text-wrap: nowrap;
    font-size: 14px;
  }
}
</style>
