<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  bot: {
    title: String,
    fun_active: Number,
    fun_total: Number,
    isDropdownOpen: Boolean,
    isActive: Boolean,
    functions: null,
  },
});

const emit = defineEmits(['showingBotFunctions']);
</script>

<template>
  <div class="bot">
    <div
      class="bot-content"
      @click="emit('showingBotFunctions')"
    >
      <img
        class="bot_img"
        src="@/assets/logo.png"
      />
      <p class="bot_title">{{ bot.title }}</p>
      <div
        class="bot_status"
        :style="{
          backgroundColor: bot.isActive ? '#4FD000' : '#FF0000',
          'box-shadow': `0px 0px 10px 0px ${
            bot.isActive ? 'rgba(79,208,0,0.8)' : 'rgba(255,0,0,0.8)'
          }`,
        }"
      ></div>
      <p class="bot_active-fun">
        Активно {{ bot.fun_active }}/{{ bot.fun_total }}
      </p>
      <img
        class="bot_action"
        :class="{ bot_action_rotated: bot.isDropdownOpen }"
        src="@/assets/arrow.svg"
      />
    </div>

    <div
      v-if="bot.isDropdownOpen"
      class="bot-functions"
    >
      <div
        v-for="(func, index) in bot.functions"
        :key="index"
        class="bot-functions_item"
      >
        <span>{{ func.title }}</span>
        <label class="bot-functions_item_switch">
          <input
            type="checkbox"
            v-model="func.isActive"
          />
          <span class="bot-functions_item_switch_slider"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bot {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  border: 0.5px solid rgba(50, 50, 50);
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  font-family: Mont;
  color: rgba(246, 246, 246);
  cursor: pointer;
}

.bot-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bot_img {
  width: 33px;
  height: 33px;
  margin-right: 10px;
  border: 0.5px solid rgba(66, 66, 66);
  border-radius: 10px;
}

.bot_title {
  font-size: 15px;
  font-weight: 700;
  margin-right: auto;
}

.bot_status {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-bottom: 2px;
  border-radius: 50%;
  background-color: #4fd000;
  box-shadow: 0px 0px 10px 0px rgba(79, 208, 0, 1);
}

.bot_active-fun {
  font-size: 12px;
  font-weight: 600;
  opacity: 70%;
}

.bot_action {
  width: 31px;
  height: 31px;
  margin-left: 30px;
  margin-right: 10px;
  padding: 8px;
  border-radius: 50%;
  background-color: rgba(40, 40, 40);
  transform: rotate(-90deg);
  transition: 0.5s ease;
}

.bot_action_rotated {
  transform: rotate(0deg);
}

.bot-functions {
  padding: 15px 65px 0px 45px;
}

.bot-functions_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #f6f6f6;
  font-family: 'Mont-Light';
  font-size: 14px;
}

.bot-functions_item_switch {
  position: relative;
  display: inline-block;
  width: 41px;
  min-width: 41px;
  height: 23px;
}

.bot-functions_item_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bot-functions_item_switch_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e1e1e;
  transition: 0.4s;
  border-radius: 24px;
  border: solid 0.5px #505050;
}

.bot-functions_item_switch_slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: #fa3c00;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .bot-functions_item_switch_slider:before {
  transform: translateX(15px);
  background-color: #4fd000;
}
</style>
