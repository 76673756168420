<script setup>
import { defineProps, ref } from 'vue';
import SidebarOption from './SidebarOption.vue';

defineProps({
  routing: null,
});

const route = ref('/stats');
</script>

<template>
  <div class="sidebar">
    <SidebarOption
      title="Статистика"
      imgName="stat.svg"
      @click="route = routing('/stats')"
      :class="route === '/stats' ? 'sidebar_current_option' : ''"
    />
    <SidebarOption
      title="Чат поддержки"
      imgName="chat.svg"
      @click="route = routing('/chat')"
      :class="route === '/chat' ? 'sidebar_current_option' : ''"
    />
    <SidebarOption
      title="Управление пользователями"
      imgName="user.svg"
      @click="route = routing('/manage')"
      :class="route === '/manage' ? 'sidebar_current_option' : ''"
    />
    <SidebarOption
      title="Настройка ботов"
      imgName="opt.svg"
      @click="route = routing('/setting')"
      :class="route === '/setting' ? 'sidebar_current_option' : ''"
    />
    <SidebarOption
      title="Массовая рассылка"
      imgName="sms.svg"
      @click="route = routing('/sending')"
      :class="route === '/sending' ? 'sidebar_current_option' : ''"
    />
    <SidebarOption
      title="Заявки на выплату"
      imgName="wallet.svg"
      @click="route = routing('/requests')"
      :class="route === '/requests' ? 'sidebar_current_option' : ''"
    />
  </div>
</template>

<style scoped>
.sidebar {
  background-color: rgba(19, 19, 19, 1);
}
.sidebar_current_option {
  color: rgb(255, 255, 255);
  opacity: 100%;
}

@media (max-width: 767px) {
  .sidebar {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid rgba(56, 56, 56, 1);
    background-color: transparent;
  }
}
</style>
